
import KeenSlider from 'keen-slider';
import 'keen-slider/keen-slider.min.css';
import { GET_POPULAR_CATEGORIES } from '@/api/apolloHomePageQueries';

export default {
  name: 'HomeViewPopularCategory',
  data() {
    return {
      popularCategories: null,
      slider: null,
      current: 0,
      activeSliders: 1,
      slidersLength: 0,
      arrowDisabled: false,
      isLoading: false,
      fetchingIsFinished: false,
      fakeList: new Array(6).map(() => ({})),
    };
  },
  async fetch() {
    await this.fetchData();
    this.fetchingIsFinished = true;
  },
  mounted() {
    setTimeout(() => {
      this.initSlider();
    }, 5);
  },
  methods: {
    initSlider() {
      const sliderEle = this.$refs.popularCategorySlider;
      if (!sliderEle) {
        return;
      }

      this.slider = new KeenSlider(sliderEle, {
        initial: this.current,
        disabled: false,
        drag: true,
        loop: true,
        slides: {
          perView: this.activeSliders,
        },
        slideChanged: (s) => {
          this.current = s.track.details.rel;
        },
      });
    },
    async fetchData() {
      try {
        this.isLoading = true;
        const { home } = await this.$graphql.default.request(GET_POPULAR_CATEGORIES) || {};
        if (home.popularCategories) {
          this.popularCategories = home.popularCategories;
        } else {
          console.error('Invalid response format:', home);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
