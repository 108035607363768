
import { mapState } from 'vuex';

export default {
  name: 'HomePage',
  head() {
    return {
      script: [
        {
          src: 'https://static.criteo.net/js/ld/ld.js',
          async: true,
          skip: !this.$config.criteo.criteoDefaultEnable,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.customer,
    }),
  },
  watch: {
    customer() {
      this.setIntegrationsEvents();
    },
  },
  methods: {
    async pushCriteoEvents() {
      if (!(window && this.$config.criteo.criteoDefaultEnable)) {
        return;
      }
      this.$taskManager.append(() => {
        // eslint-disable-next-line no-nested-ternary
        const deviceType = /iPad/.test(navigator.userAgent) ? 't'
          : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Opera Mini|Silk/.test(navigator.userAgent) ? 'm' : 'd';
        window.criteo_q = window.criteo_q || [];
        window.criteo_q.push(
          { event: 'setAccount', account: this.$config.criteo.criteoDefaultAccountId },
          { event: 'setSiteType', type: deviceType },
          { event: 'setEmail', email: this.customer?.me?.email },
          { event: 'viewHome' },
        );
      });
    },
    setIntegrationsEvents() {
      this.pushCriteoEvents();
    },
  },
};
