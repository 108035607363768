import { render, staticRenderFns } from "./FooterBefore.vue?vue&type=template&id=3fa322cd"
import script from "./FooterBefore.vue?vue&type=script&lang=js"
export * from "./FooterBefore.vue?vue&type=script&lang=js"
import style0 from "./FooterBefore.vue?vue&type=style&index=0&id=3fa322cd&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeViewPopupShopMoreSaveMore: require('/var/www/html/js/productlisting/components/templates/home/view/Popup/ShopMoreSaveMore.vue').default})
