
import BuyNowIcon from '@/assets/icon-sb-buy-now-160x106.svg';
import WhiteGloveIcon from '@/assets/icon-sb-white-glove-160x106.svg';
import ShopMoreIcon from '@/assets/icon-sb-shop-more-160x106.svg';

export default {
  name: 'FooterBefore',
  data() {
    return {
      showPopup: false,
      items: [
        {
          id: 1,
          slug: '/affirm-financing',
          preTitle: 'Up to 36 months*',
          title: 'Buy Now, Pay Later',
          isPopup: false,
          image: {
            src: BuyNowIcon,
            alt: 'Affirm Financing icon',
          },
        },
        {
          id: 2,
          slug: '/white-glove-delivery',
          preTitle: 'Leave the work to us',
          title: 'White Glove Delivery',
          isPopup: false,
          image: {
            src: WhiteGloveIcon,
            alt: 'White Glove Delivery icon',
          },
        },
        {
          id: 3,
          preTitle: 'The 1Stop Advantage',
          title: 'Shop More, Save More',
          isPopup: true,
          image: {
            src: ShopMoreIcon,
            alt: 'The 1Stop Advantage icon',
          },
        },
      ],
    };
  },
  methods: {
    togglePopup() {
      this.showPopup = !this.showPopup;
      this.$nuxt.$emit('change-global-scrolling', !this.showPopup);
    },
    handleClick() {
    },
  },
};
