
import 'keen-slider/keen-slider.min.css';
import KeenSlider from 'keen-slider';
import { GET_HOME_SET_COLLECTION } from '@/api/apolloHomePageQueries';
import respUtility from '@/mixins/respUtility';

export default {
  name: 'HomeViewByAsSet',
  mixins: [respUtility],
  data() {
    return {
      homeViewCollection: [],
      slider: null,
      current: 0,
      activeSliders: 4,
      slidersLength: 0,
      arrowDisabled: false,
      isLoading: false,
      isImgLoaded: [],
      fetchingIsFinished: false,
      nbsp: '\u00A0', // no-break space char
      fakeList: new Array(6),
    };
  },
  computed: {
    formattedHomeViewCollection() {
      return this.homeViewCollection?.banners?.map((item) => {
        const formatSrcset = (sources) => sources?.map((source) => `${source.srcset} ${source.media.includes(':x1') ? '1x' : '2x'}`).join(', ');

        const combinedSrcset576px = formatSrcset(item?.image?.sources?.filter((source) => source.media === '(min-width: 576px):x1'
          || source.media === '(min-width: 576px):x2'));

        const combinedSrcsetX = formatSrcset(item?.image?.sources?.filter((source) => source.media === 'x1'
          || source.media === 'x2'));

        const srcset = [
          {
            media: '(min-width: 576px)',
            srcset: combinedSrcset576px,
            key: 'source0',
          },
          {
            srcset: combinedSrcsetX,
            key: 'source1',
          },
        ];

        return {
          ...item,
          srcset,
          imageSrc: item?.image?.sources?.[0]?.srcset,
        };
      });
    },
  },
  async fetch() {
    await this.fetchData();
    this.fetchingIsFinished = true;
  },
  mounted() {
    setTimeout(() => {
      this.initSlider();
    }, 10);
  },
  methods: {
    load(index) {
      const img = this.$refs.buyAsSetSlider.querySelectorAll('img')[index];
      if (!img.getAttribute('src').includes('data:image')) {
        this.$set(this.isImgLoaded, index, true);
      }
    },
    async fetchData() {
      try {
        this.isLoading = true;
        const { home } = await this.$graphql.default.request(GET_HOME_SET_COLLECTION) || {};
        if (home.homeSetCollection) {
          this.homeViewCollection = home.homeSetCollection;
        } else {
          console.error('Invalid response format:', home);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.isLoading = false;
      }
    },
    initSlider() {
      const sliderEle = this.$refs.buyAsSetSlider;
      if (!sliderEle) {
        return;
      }

      this.slider = new KeenSlider(sliderEle, {
        initial: this.current,
        disabled: true,
        drag: true,
        loop: true,
        breakpoints: {
          '(min-width: 1264px)': {
            mode: 'snap',
            drag: true,
            disabled: false,
            slides: { perView: this.activeSliders, spacing: 24 },
          },
        },
        slideChanged: (s) => {
          this.current = s.track.details.rel;
        },
      });
      this.slider.slidersLength = this.slider.track?.details?.slides?.length;
      this.slider.arrowDisabled = this.slider.track?.details?.slides?.length
        <= Math.floor(this.activeSliders);
    },
    getLgText(item) {
      let lgText;
      if (this.$device.isMobile && item?.lgText?.toLowerCase()?.includes('bar & game room sets')) {
        lgText = 'Bar Room Sets';
      } else {
        lgText = item?.lgText;
      }
      return lgText;
    },
  },
};
