export default {
  data() {
    return {
      stageMounted: false,
    };
  },
  mounted() {
    this.stageMounted = true;
  },
  computed: {
    currentView() {
      if (process.server || !this.stageMounted || (process.client && this.$isSpider)) {
        return this.viewDeterminationMethod1();
      }

      if ((this.$isSpider && this.stageMounted) || !this.$isSpider) {
        return this.viewDeterminationMethod2();
      }

      return null;
    },
  },
  methods: {
    viewDeterminationMethod1() {
      const {
        isMobile: isPhone,
        isTablet,
        isDesktop,
      } = this.$device || {};
      const isMobile = isPhone || isTablet;

      const value = {
        isPhone,
        isTablet,
        isMobile,
        isDesktop,
      };

      return value;
    },
    viewDeterminationMethod2() {
      const {
        xs, sm, md, lg, xl,
      } = this?.$vuetify?.breakpoint || {};

      const isPhone = xs || sm;
      const isTablet = md;
      const isMobile = isPhone || isTablet;
      const isDesktop = lg || xl;

      const value = {
        isPhone,
        isTablet,
        isMobile,
        isDesktop,
      };

      return value;
    },
  },
};
