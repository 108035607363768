
import { GET_FEATURED_BRAND_DATA } from '@/api/apolloHomePageQueries';

export default {
  name: 'HomeViewFeatureBrands',
  props: {
    shopAllBrandData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      brands: null,
      loading: false,
      nbsp: '\u00A0', // no-break space char
      fetchingIsFinished: false,
      fakeList: new Array(4),
    };
  },
  computed: {
    formattedbrands() {
      if (!this.brands) {
        return null;
      }
      return this.brands?.map((item) => {
        const formatSrcset = (sources) => sources?.map((source) => `${source.srcset} ${source.media.includes(':x1') ? '1x' : '2x'}`).join(', ');

        const combinedSrcset576px = formatSrcset(item?.image?.sources?.filter((source) => source.media === '(min-width: 576px):x1'
          || source.media === '(min-width: 576px):x2'));

        const combinedSrcsetX = formatSrcset(item?.image?.sources?.filter((source) => source.media === 'x1'
          || source.media === 'x2'));

        const srcset = [
          {
            media: '(min-width: 576px)',
            srcset: combinedSrcset576px,
            key: 'source0',
          },
          {
            srcset: combinedSrcsetX,
            key: 'source1',
          },
        ];

        return {
          ...item,
          srcset,
          imageSrc: item?.image?.sources?.[0]?.srcset,
        };
      });
    },
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        const { home: { featureBrand } } = await this.$graphql.default.request(GET_FEATURED_BRAND_DATA) || {};
        if (featureBrand.banners) {
          this.brands = featureBrand.banners;
        } else {
          console.error('Invalid response format');
        }
        this.loading = false;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
  },
  async fetch() {
    await this.fetchData();
    this.fetchingIsFinished = true;
  },
};
