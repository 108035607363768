
import respUtility from '@/mixins/respUtility';
import KeenSlider from 'keen-slider';
import 'keen-slider/keen-slider.min.css';
import { GET_CLOUD_LOOKS } from '@/api/apolloHomePageQueries';

export default {
  name: 'HomeViewCloudSofa',
  mixins: [respUtility],
  data() {
    return {
      transparentOnePixel: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
      cloudLooks: [],
      slider: null,
      loading: false,
      fetchingIsCompleted: false,
      nbsp: '\u00A0', // no-break space char
      isClient: false,
    };
  },
  computed: {
    hasInternalRoute() {
      return Boolean(this.cloudLooks?.button?.internalRoute);
    },
  },
  async fetch() {
    await this.fetchData();
    this.fetchingIsCompleted = true;
  },
  mounted() {
    this.isClient = true;
  },
  watch: {
    // Watch for data changes to initialize slider when data arrives after mounting
    'cloudLooks.banners': {
      handler(newBanners) {
        if (newBanners?.length > 0 && this.isClient && !this.slider) {
          setTimeout(() => {
            this.initKeenSlider();
          }, 15);
        }
      },
      immediate: true,
    },
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        const {
          home,
          home: { cloudLooks },
        } = await this.$graphql.default.request(GET_CLOUD_LOOKS) || {};

        if (cloudLooks) {
          this.cloudLooks = cloudLooks;
        } else {
          console.error('Invalid response format:', home);
        }

        this.loading = false;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    initKeenSlider() {
      // Double-check we're in the browser and the element exists
      if (typeof window === 'undefined' || !this.$refs.slider) return;

      const options = {
        mode: 'free-snap',
        loop: true,
        dragSpeed: 0.75,
        defaultAnimation: {
          duration: 1000,
        },
        slides: {
          origin: 'center',
          perView: 1,
          spacing: 0,
        },
        breakpoints: {
          '(min-width: 768px)': {
            slides: {
              origin: 'center',
              perView: 1.7,
              spacing: 0,
            },
          },
        },
      };

      const plugins = [
        (slider) => {
          // Function to safely handle DOM operations
          const setupSliderDOM = () => {
            const getTranslate3d = (transformValue) => {
              if (!transformValue) {
                return null;
              }

              const values = transformValue?.split(/\w+\(|\);?/);

              if (!values) {
                return null;
              }

              return values[1].split(/,\s?/g).map(parseFloat);
            };

            const mathRound = (number, precision = 100) => (
              Math.round(parseFloat(number) * precision) / precision
            );

            const markup = (remove) => {
              if (remove) {
                slider.container.classList.remove(this.$style.isInitialized);
                return;
              }
              slider.container.classList.add(this.$style.isInitialized);
            };

            const setCSSVariables = (removeCSSVariables) => {
              const {
                container,
                slides,
                size,
                options: {
                  trackConfig,
                },
              } = slider;

              if (removeCSSVariables) {
                requestAnimationFrame(() => {
                  for (let i = 0; i < slides.length; i += 1) {
                    slides[i].style.removeProperty('--slide-index');
                    slides[i].style.removeProperty('--track-offset');
                  }
                  container.style.removeProperty('--slide-width');
                });
                return;
              }

              requestAnimationFrame(() => {
                const slideWidth = mathRound(size * trackConfig[0].size, 1000);
                const baseTrackOffset = -1 * mathRound(size * trackConfig[0].origin, 1000);
                for (let i = 0; i < slides.length; i += 1) {
                  const $slide = slides[i];
                  const transform = $slide.style.getPropertyValue('transform');

                  $slide.style.setProperty('--slide-index', i);
                  Object.defineProperty($slide.style, 'transform', {
                    get() {
                      return this.transformProperty;
                    },
                    set(value) {
                      this.transformProperty = value;
                      requestAnimationFrame(() => {
                        if (!$slide.style.transform) {
                          return;
                        }

                        const translate3d = getTranslate3d(value);

                        if (translate3d === null || translate3d === undefined) {
                          return;
                        }

                        const translateX = mathRound(translate3d[0], 1000);
                        const trackOffset = mathRound(translateX + baseTrackOffset, 1000);
                        const trackOffsetOld = Number($slide.style.getPropertyValue('--track-offset') || undefined) || null;

                        if (trackOffsetOld === trackOffset) {
                          return;
                        }

                        $slide.style.setProperty('--track-offset', trackOffset);
                      });
                    },
                  });
                  $slide.style.transform = transform;
                }
                container.style.setProperty('--slide-width', slideWidth);
              });
            };

            slider.on('created', () => {
              markup();
              setCSSVariables();
            });
            slider.on('optionsChanged', () => {
              markup(true);
              markup();
              setCSSVariables(true);
              setCSSVariables();
            });
            slider.on('updated', () => {
              setCSSVariables();
            });
            slider.on('destroyed', () => {
              setCSSVariables(true);
              markup(true);
            });
            slider.on('animationStarted', (instance) => {
              instance.container.classList.add(this.$style.isEnabledTransition);
            });
            slider.on('animationEnded', (instance) => {
              const { details } = instance.track;

              instance.container.classList.remove(this.$style.isEnabledTransition);

              if ((details.rel === 0) && (details.abs !== 0)) {
                /** Prevent carousel scrolling to the beginning. SB-23379 */
                instance.track.to(0);
              }
            });
          };

          // Only run DOM operations if we're in the browser
          if (typeof window !== 'undefined') {
            setupSliderDOM();
          }
        },
      ];

      // Create the slider instance
      this.$nextTick(() => {
        try {
          this.slider = new KeenSlider(this.$refs.slider, options, plugins);
        } catch (error) {
          console.error('Error initializing Keen Slider:', error);
        }
      });
    },
    prev() {
      if (this.slider) {
        this.slider.prev();
      }
    },
    next() {
      if (this.slider) {
        this.slider.next();
      }
    },
    onLoad(el) {
      if (!el) return;

      const img = el.querySelector('img');
      if (!img || img.src.includes('data:image')) {
        return;
      }

      const container = el.closest(`.${this.$style.pictureContainer}`);
      if (container) {
        container.classList.add(this.$style.isLoaded);
      }
    },
  },
  beforeDestroy() {
    if (this.slider) {
      this.slider.destroy();
      this.slider = null;
    }
  },
};
