
import respUtility from '@/mixins/respUtility';
import placeholder from '@/assets/placeholder-animation.svg';

export default {
  name: 'HomeViewPopularCategoryItem',
  mixins: [respUtility],
  props: {
    category: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      placeholder,
      nbsp: '\u00A0', // no-break space char
      isLoaded: false,
    };
  },
  computed: {
    getTitle() {
      let title;
      if (this.$device.isMobile && this.category?.button?.title?.toLowerCase()?.includes('shop outdoor')) {
        title = 'Shop Outdoor Sets';
      } else {
        title = this.category?.button?.title;
      }
      return title;
    },
  },
  methods: {
    onLoad(el) {
      if (el?.src?.includes('data:image')) {
        return;
      }
      this.isLoaded = true;
    },
  },
};
