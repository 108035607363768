
import respUtility from '@/mixins/respUtility';
import placeholder from '@/assets/placeholder-animation.svg';

export default {
  name: 'HomeViewPopupShopMoreSaveMore',
  mixins: [respUtility],
  data: () => ({
    title: 'Shop More Save More',
    placeholder,
    content: [
      'Here’s an industry secret: when it comes to online shopping: the “Free Shipping” we all know and love isn’t free. It typically means the merchant has built the price of shipping into the price of the item itself. We don’t think that’s fair to you.',
      'Our mission is to provide our customers with the best possible value and full transparency, so when you buy more, we recalculate shipping costs—and you save more. This is one of our most innovative systems, built with one goal: saving you money.',
    ],
    list: [
      {
        image: 'skin/frontend/onestopbedrooms/aboutonestop/images/discount.png',
        subTitle: 'It starts with discounts',
        description: 'At 1StopBedrooms we offer special discounted pricing on furniture sets.',
      },
      {
        image: 'skin/frontend/onestopbedrooms/aboutonestop/images/add-pieces.png',
        subTitle: 'Add pieces and save',
        description: 'The more pieces you order from any particular set, the greater the discount you receive.',
      },
      {
        image: 'skin/frontend/onestopbedrooms/aboutonestop/images/calc.png',
        subTitle: 'We ship smarter & save you more!',
        description: 'We calculate the savings from shipping multiple items and then pass that savings onto you!',
      },
    ],
  }),
  computed: {
    isBoutiquePage() {
      return this.$store.state.metaData?.addons?.head?.isBoutique || false;
    },
  },
};
