
export default {
  name: 'HomeViewClearanceBanner',
  props: {
    clearanceItems: {
      type: Array,
      default() {
        return [];
      },
    },
    fetchingIsFinished: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fakeList: new Array(2),
    };
  },
};
