
export default {
  name: 'HomeViewStaticBannerBrand',
  props: {
    theOdoreData: {
      type: Object,
      default() {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
