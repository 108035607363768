import { render, staticRenderFns } from "./view.vue?vue&type=template&id=21f7058c"
import script from "./view.vue?vue&type=script&lang=js"
export * from "./view.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeViewBanner: require('/var/www/html/js/productlisting/components/templates/home/view/Banner.vue').default,HomeViewCategory: require('/var/www/html/js/productlisting/components/templates/home/view/Category.vue').default,HomeViewPopularCategory: require('/var/www/html/js/productlisting/components/templates/home/view/PopularCategory.vue').default,HomeViewBuyAsSet: require('/var/www/html/js/productlisting/components/templates/home/view/BuyAsSet.vue').default,HomeViewCloudSofa: require('/var/www/html/js/productlisting/components/templates/home/view/CloudSofa.vue').default,HomeViewStaticBannerBrand: require('/var/www/html/js/productlisting/components/templates/home/view/StaticBannerBrand.vue').default,HomeViewFeatureBrands: require('/var/www/html/js/productlisting/components/templates/home/view/FeatureBrands.vue').default,HomeViewClearanceBanner: require('/var/www/html/js/productlisting/components/templates/home/view/ClearanceBanner.vue').default})
