// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bannerBrand_dVJt\\+{margin:6rem auto 0;max-width:1648px;padding:0 1.6rem;width:100%}@media only screen and (min-width:768px){.bannerBrand_dVJt\\+{margin:6.4rem auto 0;padding:0 2.4rem}}@media only screen and (min-width:1264px){.bannerBrand_dVJt\\+{margin:5.5rem auto 0;padding:0 2.4rem}}.inner_Yr3kY{position:relative}.inner_Yr3kY.isLoading_R\\+XCV:before{background-color:var(--cp-color-100);border-radius:.8rem;bottom:0;content:\"\";display:block;left:0;position:absolute;right:0;top:0}.skeleton_Uw4lQ{position:absolute;top:0;z-index:1}.link_2H7VX{background-color:var(--cp-gray-9);border-radius:.8rem;display:block;opacity:1;overflow:hidden}.link_2H7VX.isHidden_IJSmi{opacity:0}.imageWrap_6C2oh{border-radius:.8rem;padding-top:114.8688%;position:relative}@media only screen and (min-width:768px){.imageWrap_6C2oh{border-radius:.4rem;padding-top:12.22222%}}@media only screen and (min-width:1264px){.imageWrap_6C2oh{border-radius:.8rem;padding-top:11.25%}}.imageWrap_6C2oh img{height:100%;left:0;-o-object-fit:contain;object-fit:contain;position:absolute;top:0;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerBrand": "bannerBrand_dVJt+",
	"inner": "inner_Yr3kY",
	"isLoading": "isLoading_R+XCV",
	"skeleton": "skeleton_Uw4lQ",
	"link": "link_2H7VX",
	"isHidden": "isHidden_IJSmi",
	"imageWrap": "imageWrap_6C2oh"
};
module.exports = ___CSS_LOADER_EXPORT___;
