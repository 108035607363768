
import { GET_ADDITIONAL_LINKS } from '@/api/apolloHomePageQueries';
import currentViewUtility from '@/mixins/currentViewUtility';
import FooterBefore from './view/FooterBefore.vue';

export default {
  name: 'HomeView',
  components: {
    FooterBefore,
  },
  mixins: [
    currentViewUtility,
  ],
  data() {
    return {
      fetchingIsFinished: false,
      clearanceItems: [],
      theOdoreLink: {},
      shopAllBrandLink: {},
      allDepartmentLink: {},
      additionalLinksLoading: false,
    };
  },
  methods: {
    async fetchData() {
      try {
        this.additionalLinksLoading = true;
        const { home } = await this.$graphql.default.request(GET_ADDITIONAL_LINKS) || {};
        if (home?.additionalLinks) {
          home.additionalLinks.forEach((additional) => {
            if (additional.identifier === 'clearance_deals' || additional.identifier === 'daily_deals') {
              this.clearanceItems.push(additional);
            } else if (additional.identifier === 'theodore_alexander_brand') {
              this.theOdoreLink = additional;
            } else if (additional.identifier === 'all_brand') {
              this.shopAllBrandLink = additional;
            } else if (additional.identifier === 'all_department') {
              this.allDepartmentLink = additional;
            }
          });
        } else {
          console.error('Invalid response format:', home);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.additionalLinksLoading = false;
      }
    },
    viewDeterminationMethod1() {
      // currentViewUtility mixin
      const { isMobile, isTablet, isDesktop } = this.$device || {};
      const value = { isMobile, isTablet, isDesktop };
      return value;
    },
    viewDeterminationMethod2() {
      // currentViewUtility mixin
      const width = this?.$vuetify?.breakpoint?.width;
      const value = {
        isMobile: false,
        isTablet: false,
        isDesktop: false,
      };

      if (width >= 1264) {
        value.isDesktop = true;
      } else if (width >= 768) {
        value.isTablet = true;
      } else {
        value.isMobile = true;
      }

      return value;
    },
  },
  async fetch() {
    await this.fetchData();
    this.fetchingIsFinished = true;
  },
};
